
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { navigate, graphql } from "gatsby";

import { ShopStore, OrderStore, UIStore, VisitorStore } from "stores";
import { SquidexTexts } from "generated/gatsbyApiTypes";
import {
  PAGE_VIEW_EVENT, NAVIGATE_EVENT, ADD_IMAGE_TO_CART_EVENT,
  CHANGE_IMAGE_EVENT, UPLOAD_IMAGE_EVENT, THUMBNAIL_MAX, getLanguageValue
} from "utils";


import Loader from "../../components/loader";
import Button from "../../components/button";
import FilterSelect from "./main/filterSelect";
import CustomSizeInput from "./main/customSizeInput";
import FormatSelect from "./main/formatSelect";
import ImageEditor from "./main/imageEditor";
import ImageUploader from "./main/imageUploader";
import PriceView from "./main/priceView";
import MaterialSelect from "./main/materialSelect";
import OptionSelect from "./main/optionSelect";
import SEO from "../../components/seo";
import OptionGroups from './main/optionGroups';

interface ShopPageState {
  previewOpen: boolean;
}

@inject("shopStore", "orderStore", "uiStore", "visitorStore")
@observer
class ShopPage extends React.Component<any, ShopPageState> {
  @observable imgLoading: boolean;
  camanApi: any;
  constructor(props: any) {
    super(props);
    this.state = {
      previewOpen: false
    };
  }
  componentWillMount() {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const shopStore: ShopStore = this.props.shopStore;
    const texts = this.props.data.squidexTexts;
    if (!shopStore.current) {
      uiStore.showModal(texts.txtGenericErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGenericErrorBody[process.env.GATSBY_LANGUAGE]);
    }
    if (typeof (document) !== "undefined") {
      const canvas = document.createElement("canvas");
      this.camanApi = Caman(canvas);
    }
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "shop-index",
    });
  }
  componentDidMount() {
    const store: OrderStore = this.props.orderStore;
    const config = this.props.data.squidexConfig;
    if (!config.allowImageUpload.iv && !store.currentItem.hasImage) {
      if (typeof (window) !== "undefined") {
        const visitorStore: VisitorStore = this.props.visitorStore;
        visitorStore.logEvent(NAVIGATE_EVENT, {
          location: this.props.location,
          href: this.props.data.squidexGalleryOverviewPage.slug.iv.toLowerCase()
        });
        navigate(this.props.data.squidexGalleryOverviewPage.slug.iv.toLowerCase());
      }
    }
    if (!!store.currentItem.image) {
      this.imgLoading = true;
      this.createThumb();
    } else {
      this.imgLoading = false;
    }
  }
  render() {
    const store: OrderStore = this.props.orderStore;
    const config = this.props.data.squidexConfig;
    if (typeof (window) === "undefined" || (!config.allowImageUpload.iv && !store.currentItem.hasImage)) {
      return <Loader />
    }
    const texts = this.props.data.squidexTexts as SquidexTexts;
    const aspectSelectRow = (store.currentItem.isCustomSize) ? <CustomSizeInput /> : <><FilterSelect /><FormatSelect /></>
    return (
      <>
        <SEO subTitle={getLanguageValue(texts.pageShopIndex)} />
        {this.maybeRenderLoader()}
        <h1>{getLanguageValue(texts.pageShopIndex)}</h1>
        <div style={{ display: this.state.previewOpen ? "none" : undefined }} className="h-100 flex flex-row-ns flex-column justify-between">
          <div className="w-100 w-50-ns pr2-ns">
            <div className="inline-flex flex-row items-center">
              <div className="dib br-100 near-white bg-theme w2 lh-copy tc f4">1</div>
              <h3 className="dib ma0 ml2">{getLanguageValue(texts.txtChooseProduct)}</h3>
            </div>
            <div className="center br3 hidden ba b--black-10 mv3 bg-near-white">
              <div className="f6 f5-ns pa2 lh-copy">
                <MaterialSelect />
                {aspectSelectRow}
                <OptionSelect />
                <OptionGroups />
                <PriceView />
              </div>
            </div>
          </div>
          <div className="w-100 w-50-ns pl2-ns">
            <div className="inline-flex flex-row items-center">
              <div className="dib br-100 near-white bg-theme w2 lh-copy tc f4">2</div>
              <h3 className="dib ma0 ml2">{getLanguageValue(texts.txtChoosePicture)}</h3>
            </div>
            <div className="center br3 hidden ba b--black-10 mv3 bg-near-white tc">
              {store.currentItem.hasThumb
                ? <ImageEditor onEffectChange={this.createThumb} onImageReset={this.handleImageReset} />
                : <ImageUploader onImageUploaded={this.createThumb} onStartUploading={this.handleUploadStart} />}
            </div>
            <div className="ph2 tc tr-ns">
              <Button size={4} className="mb2" label={getLanguageValue(texts.btnAddToCart)} color="theme" onClick={this.handleShowPreview} />
            </div>
          </div>
        </div>
        <div style={{ display: this.state.previewOpen ? undefined : "none" }}>
          <div className="inline-flex flex-row items-center">
            <div className="dib br-100 near-white bg-theme w2 lh-copy tc f4">3</div>
            <h3 className="dib ma0 ml2">{getLanguageValue(texts.txtImagePreview)}</h3>
          </div>
          <div className="center br3 ba b--black-10 mv3 bg-near-white tc">
            <div className="f6 f5-ns pa2 lh-copy">
              <div id="imgFinal" className="dib">
                <canvas id="cnvImgFinal" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div className="cf">
            <div className="pa2 tc fl-ns">
              <Button label={getLanguageValue(texts.btnBackToEditImage)} color="theme" inversed onClick={this.handleHidePreview} />
            </div>
            <div className="pa2 tc fr-ns">
              <Button size={4} className="mb2" label={getLanguageValue(texts.btnAddToCart)} color="theme" onClick={this.handleItemSubmit} />
            </div>
          </div>
        </div>
      </>
    )
  }
  maybeRenderLoader = () => {
    const store: ShopStore = this.props.shopStore;
    if (store.fetching || this.imgLoading) {
      return (<Loader />);
    }
    return null;
  }
  handleUploadStart = () => {
    this.imgLoading = true;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(UPLOAD_IMAGE_EVENT);
  }
  createThumb = () => {
    this.createThumbAsync();
  }
  createThumbAsync = () => {
    const component = this;
    const store: OrderStore = this.props.orderStore;
    return new Promise((res) => {
      this.renderInCaman((img) => function () {
        this.resize({
          width: img.naturalWidth > img.naturalHeight ? THUMBNAIL_MAX : undefined,
          height: img.naturalHeight > img.naturalWidth ? THUMBNAIL_MAX : undefined,
        })
        if (store.currentItem.effect !== "geen") {
          this[store.currentItem.effect]();
        }
        this.render(function () {
          store.currentItem.thumb = this.toBase64();
          component.imgLoading = false;
          res(undefined);
        });
      });
    })
  }
  appendImagePreview = () => {
    const store: OrderStore = this.props.orderStore;
    var img = new Image();
    img.onload = function () {
      // const scale = store.currentItem.crop.originalWidth / store.currentItem.crop.width;
      const scale = 1;
      const w = Math.round((1 / scale) * store.currentItem.crop.width);
      const h = Math.round((1 / scale) * store.currentItem.crop.height);
      const x = Math.round((1 / scale) * store.currentItem.crop.x);
      const y = Math.round((1 / scale) * store.currentItem.crop.y);
      const canvas = document.getElementById("cnvImgFinal") as HTMLCanvasElement;
      const context = canvas.getContext("2d");
      canvas.width = w;
      canvas.height = h;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.save();
      context.translate(canvas.width / 2, canvas.height / 2);
      context.drawImage(img, x, y, w, h, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      context.restore();
    }
    img.src = store.currentItem.thumb;
  }
  handleShowPreview = () => {
    this.appendImagePreview();
    this.setState({ previewOpen: true });
  }
  handleHidePreview = () => {
    this.setState({ previewOpen: false });
  }
  handleItemSubmit = () => {
    if (!this.validate()) {
      return;
    }
    this.submitItemAsync();
  }
  submitItemAsync = async () => {
    await this.createThumbAsync();
    const store: OrderStore = this.props.orderStore;
    store.submitItem();
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(ADD_IMAGE_TO_CART_EVENT, {
      ...store.currentItem
    });

    setTimeout(() => {
      visitorStore.logEvent(NAVIGATE_EVENT, {
        location: this.props.location,
        href: this.props.data.squidexConfig.pageCartSlug.iv.toLowerCase()
      });

      navigate(this.props.data.squidexConfig.pageCartSlug.iv.toLowerCase())
    }, 200);
  }
  renderInCaman = (camanFn: (i: HTMLImageElement) => Function) => {
    const store: OrderStore = this.props.orderStore;
    this.imgLoading = true;
    const img = new Image();
    img.src = store.currentItem.image;
    img.onload = () => {
      const fn = () => {
        const canvas: HTMLCanvasElement = this.camanApi.canvas;
        if (!canvas) {
          setTimeout(fn, 0);
          return;
        }
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        this.camanApi.finishInit();
        this.camanApi.render(camanFn(img));
      }
      fn();
    }
  }
  handleImageReset = () => {
    const config = this.props.data.squidexConfig;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(CHANGE_IMAGE_EVENT);
    if (!config.allowImageUpload.iv) {
      visitorStore.logEvent(NAVIGATE_EVENT, {
        location: this.props.location,
        href: this.props.data.squidexGalleryOverviewPage.slug.iv.toLowerCase()
      });

      navigate(this.props.data.squidexGalleryOverviewPage.slug.iv.toLowerCase());
    } else {
      const store: OrderStore = this.props.orderStore;
      store.currentItem.thumb = undefined;
      store.currentItem.image = undefined;
    }
  }
  validate = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const texts = this.props.data.squidexTexts;
    if (!store.currentItem.material) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoMaterialError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (store.currentItem.material.customSizes) {
      if (!store.currentItem.height) {
        uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoHeightError[process.env.GATSBY_LANGUAGE]);
        return false;
      }
      if (!store.currentItem.width) {
        uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoWidthError[process.env.GATSBY_LANGUAGE]);
        return false;
      }
    } else if (!store.currentItem.format) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoFormatError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!store.currentItem.image) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoImageError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    return true;
  }
}

export default ShopPage;
export const pageQuery = graphql`
{
  squidexConfig(identity: { eq: "config" }) {
    pageCartSlug {
      iv
    }
    allowImageUpload {
      iv
    }
  }
  squidexGalleryOverviewPage(identity: { eq: "galleryOverview"}) {
    slug {
      iv
    }
  }
    squidexTexts(identity:{eq: "squidextexts"}) {
      pageShopIndex {
        de
        nl
        en
      }
      txtChooseProduct {
        de
        nl
        en
      }
      txtChoosePicture {
        de
        nl
        en
      }
      btnAddToCart {
        de
        nl
        en
      }
      txtGenericErrorHeader {
        de
        nl
        en
      }
      txtGenericErrorBody {
        de
        nl
        en
      }
      txtValidationErrorHeader {
        de
        nl
        en
      }
      txtNoMaterialError {
        de
        nl
        en
      }
      txtNoWidthError {
        de
        nl
        en
      }
      txtNoHeightError {
        de
        nl
        en
      }
      txtNoFormatError {
        de
        nl
        en
      }
      txtNoImageError {
        de
        nl
        en
      }
      txtImagePreview {
        de
        nl
        en
      }
      btnToImagePreview {
        de
        nl
        en
      }
      btnBackToEditImage {
        de
        nl
        en
      }
    }
  } 
`

