
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, VisitorStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_EFFECT_EVENT } from "../../../utils/constants";

export interface EffectSelectProps {
  orderStore?: OrderStore;
  visitorStore?: VisitorStore;
  onChange?: (effect: string) => void;
}

@inject("orderStore", "visitorStore")
@observer
export default class EffectSelect extends React.Component<EffectSelectProps> {
  render() {
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtEffect {
            de
            nl
            en
          }
          txtChooseEffect {
            de
            nl
            en
          }
        }
        squidexConfig(identity:{eq: "config"}) {
          effects {
            de {
              label
              camanMethod
            }
            nl {
              label
              camanMethod
            }
            en {
              label
              camanMethod
            }
          }
        }
      }`} render={
          (data) => {
            const effects = data.squidexConfig.effects[process.env.GATSBY_LANGUAGE].map(({label, camanMethod}) => ({ label, value: camanMethod }));
            const placeholderText = data.squidexTexts.txtChooseEffect[process.env.GATSBY_LANGUAGE];
            let defaultValue = effects[0];
            if (this.props.orderStore.currentItem.effect) {
              defaultValue = effects.find(e => e.value === this.props.orderStore.currentItem.effect);
            }
            return (
              <div id="effect-row" className="tl mt2 mb2">
                <label className="f4 b db mb2">{data.squidexTexts.txtEffect[process.env.GATSBY_LANGUAGE]}</label>
                <SelectList placeholder={placeholderText} defaultValue={defaultValue} items={effects} onChange={this.handleEffectChange} />
              </div>
            )
          }
        } />
    )
  }


  handleEffectChange = (t: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(SELECT_EFFECT_EVENT, t.value);
    // TODO: Should change this in backend
    if (t.value === "none") {
      store.currentItem.effect = "geen"; 
    } else {
      store.currentItem.effect = t.value;
    }
    if (this.props.onChange) {
      this.props.onChange(t.value);
    }
  }
}


