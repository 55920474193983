
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, VisitorStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_OPTION_EVENT } from "../../../utils/constants";

export interface OptionSelectProps {
  orderStore?: OrderStore;
  visitorStore?: VisitorStore;
}

@inject("orderStore", "visitorStore")
@observer
export default class OptionSelect extends React.Component<OptionSelectProps> {
  render() {
    const orderStore = this.props.orderStore;
    if (!orderStore.currentItem.material || !orderStore.currentItem.material.options || orderStore.currentItem.material.options.length === 0) {
      return null;
    }
    const options = orderStore.currentItem.material.options
      .filter(opt => !orderStore.currentItem.material.optionGroups.some(
        og => og.options.map(o => o.id).indexOf(opt.id) !== -1
      ))
      .map(opt => ({ label: opt.name, value: opt }));
    const defaultValue = options.find(f => f.label === this.props.orderStore.currentItem.options[0].name);
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtOption {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => (
            <div id="format-row">
              <label className="f4 b db mb2">{data.squidexTexts.txtOption[process.env.GATSBY_LANGUAGE]}</label>
              <SelectList defaultValue={defaultValue} items={options} onChange={this.handleOptionChange} />
            </div>
          )
        } />
    )
  }

  handleOptionChange = (t: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.options[0] = t.value;
    
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(SELECT_OPTION_EVENT, t.value);
  }
}


