
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, UIStore, VisitorStore } from "stores";
import Input from "../../../components/input";
import { INPUT_CUSTOM_FORMAT_EVENT } from "../../../utils/constants";

export interface CustomSizeInputProps {
  orderStore?: OrderStore;
  uiStore?: UIStore;
  visitorStore?: VisitorStore;
}

@inject("orderStore", "uiStore", "visitorStore")
@observer
export default class CustomSizeInput extends React.Component<CustomSizeInputProps> {
  data: any;
  render() {
    const orderStore: OrderStore = this.props.orderStore;
    if (!orderStore.currentItem.isCustomSize) {
      return null;
    }
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtLength {
            de
            nl
            en
          }
          txtWidth {
            de
            nl
            en
          }
          txtGenericErrorHeader {
            de
            nl
            en
          }
          txtGenericErrorBody {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => {
            this.data = data;
            return (
              <div id="custom-row">
                <Input label={data.squidexTexts.txtLength[process.env.GATSBY_LANGUAGE]} onChange={this.handleHeightChange} initialValue={orderStore.currentItem.height} />
                <Input label={data.squidexTexts.txtWidth[process.env.GATSBY_LANGUAGE]} onChange={this.handleWidthChange} initialValue={orderStore.currentItem.width} />
              </div>
            )
          }
        } />
    )
  }
  handleHeightChange = (value: string) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.height = parseInt(value);
    this.getPriceInfo();
  
  }
  handleWidthChange = (value: string) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.width = parseInt(value);
    this.getPriceInfo();
  }
  getPriceInfo = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const texts = this.data.squidexTexts;

    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(INPUT_CUSTOM_FORMAT_EVENT, {
      width: store.currentItem.width,
      height: store.currentItem.height
    });

    if (!isNaN(store.currentItem.height) && !isNaN(store.currentItem.width)) {
      store.getCustomPrice().then(result => {
        if (typeof(result) === "undefined") {
          uiStore.showModal(texts.txtGenericErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGenericErrorBody[process.env.GATSBY_LANGUAGE]);
        }
      });
    }
  }
}


