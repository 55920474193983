
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, VisitorStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_FORMAT_EVENT } from "../../../utils/constants";

export interface FormatSelectProps {
  orderStore?: OrderStore;
  visitorStore?: VisitorStore
}

@inject("orderStore", "visitorStore")
@observer
export default class FormatSelect extends React.Component<FormatSelectProps> {
  render() {
    const orderStore = this.props.orderStore;
    if (!orderStore.currentItem.material || orderStore.currentItem.isCustomSize) {
      return null;
    }
    const formats = orderStore.currentItem.material.formats
      .filter(f => f.houding === orderStore.currentItem.filter)
      .map(f => ({ label: f.name, value: f }));
    const defaultValue = formats.find(f => f.label === this.props.orderStore.currentItem.format.name);
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtFormat {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => (
            <div id="format-row">
              <label className="f4 b db mb2">{data.squidexTexts.txtFormat[process.env.GATSBY_LANGUAGE]}</label>
              <SelectList defaultValue={defaultValue} items={formats} onChange={this.handleFormatChange} />
            </div>
          )
        } />
    )
  }

  handleFormatChange = (t: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.format = t.value;
    store.currentItem.rawPrice = t.value.price;
    
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(SELECT_FORMAT_EVENT, t.value);
  }
}


