
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";

export interface PriceViewProps {
  orderStore?: OrderStore;
}

@inject("orderStore")
@observer
export default class PriceView extends React.Component<PriceViewProps> {
  render() {
    const orderStore = this.props.orderStore;
    if (isNaN(orderStore.currentItem.price) || orderStore.currentItem.price <= 0) {
      return null;
    }
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtYourPrice {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => (
            <div id="price-row">
              <p className="f4 b db mt0 mb2">{data.squidexTexts.txtYourPrice[process.env.GATSBY_LANGUAGE]}</p>
              <p id="product-price" className="f5 mt0 db">&euro; {orderStore.currentItem.price.toFixed(2)}</p>
            </div>
          )
        } />
    )
  }
}


