
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";
import Dropzone from "react-dropzone";

import { OrderStore } from "stores";
import { SquidexConfig, SquidexTexts } from "generated/gatsbyApiTypes";
import { Button } from "components";
import { getLanguageValue } from "utils";

export interface ImageUploaderProps {
  orderStore?: OrderStore;
  onStartUploading?: () => void;
  onImageUploaded?: (img: string) => void;
}

export interface ImageUploaderState {
  hasFileSizeError: boolean;
  hasFileTypeError: boolean;
}

@inject("orderStore")
@observer
export default class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
  dropZone: Dropzone;
  maxSize: number;
  constructor(props: ImageUploaderProps) {
    super(props);
    this.state = {
      hasFileSizeError: false,
      hasFileTypeError: false
    }
  }
  render() {
    return (
      <StaticQuery query={graphql`
      {
        config: squidexConfig(identity: {eq: "config"}) {
          uploadLimit {
            iv
          }
        }
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtUploadInstructions {
            de
            nl
            en
          }
          btnShopUpload {
            de
            nl
            en
          }
          txtFileTooLargeError {
            de
            nl
            en 
          }
          txtFileIncorrectTypeError {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => {
            const texts = data.squidexTexts as SquidexTexts;
            const config = data.config as SquidexConfig;
            this.maxSize = config.uploadLimit.iv;
            return (
              <div id="dropzone" className="f6 f5-ns pa2 lh-copy">
                <p className="f4 b db ma0 mb2">{getLanguageValue(texts.txtUploadInstructions)}</p>
                <Dropzone
                  accept="image/*"
                  maxSize={this.maxSize * 1024 * 1024}
                  className="b--dashed b--black-75 h5"
                  ref={(d) => { this.dropZone = d }}
                  onDrop={this.handleFileUpload}
                />

                {this.state.hasFileSizeError ? <p className="red f4">{getLanguageValue(texts.txtFileTooLargeError)}</p> : null}
                {this.state.hasFileTypeError ? <p className="red f4">{getLanguageValue(texts.txtFileIncorrectTypeError)}</p> : null}
                <Button size={4} className="mt2" label={getLanguageValue(texts.btnShopUpload)} color="theme" onClick={this.handleUploadClick} />
              </div>
            )
          }
        } />
    )
  }
  handleUploadClick = () => {
    this.dropZone.open();
  }
  handleFileUpload = (acceptedFiles: File[], rejectFiles: File[]) => {
    const store: OrderStore = this.props.orderStore;
    const file = acceptedFiles[0];
    if (!file) {
      const rejected = rejectFiles[0];
      if (rejected.size > this.maxSize * 1024 * 1024) {
        this.setState({ hasFileSizeError: true, hasFileTypeError: false });
      } else {
        this.setState({ hasFileSizeError: false, hasFileTypeError: true });
      }
      return;
    }
    store.currentItem.effect = "geen";
    const reader = new FileReader();
    reader.onload = () => {
      store.currentItem.image = reader.result as string;
      if (this.props.onImageUploaded) {
        this.props.onImageUploaded(reader.result as string);
      }
    }
    reader.readAsDataURL(file);
    this.setState({ hasFileSizeError: false, hasFileTypeError: false });
  }
}

