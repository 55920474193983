
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer, Observer } from "mobx-react";

import { OrderStore, UIStore } from "stores";
import Cropper, { ICropLocation, ICrop } from "react-easy-crop";
import Button from "../../../components/button";
import EffectSelect from "./effectSelect";

export interface ImageEditorProps {
  orderStore?: OrderStore;
  uiStore?: UIStore;
  onEffectChange?: (effect: string) => void;
  onImageReset?: () => void;
}
export interface ImageEditorState {
  zoom: number;
  cropLoc: ICropLocation;
}

@inject("orderStore", "uiStore")
@observer
export default class ImageEditor extends React.Component<ImageEditorProps, ImageEditorState> {

  constructor(props: ImageEditorProps) {
    super(props);
    this.state = {
      zoom: 1,
      cropLoc: {
        x: 0,
        y: 0
      }
    }
  }
  componentWillMount() {
    // Update state to get cropper right
    setTimeout(() => {
      this.setState({});
    }, 0)
  }
  render() {
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtEditPicture {
            de
            nl
            en
          }
          txtEditPictureInstructions {
            de
            nl
            en
          }
          txtEffect {
            de
            nl
            en
          }
          btnChangePicture {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => (
            <div id="image-editor" className="pa2">
              <p className="f4 b db ma0">
                {data.squidexTexts.txtEditPicture[process.env.GATSBY_LANGUAGE]}
              </p>
              <p className="f6 db black-70 mt0">
                {data.squidexTexts.txtEditPictureInstructions[process.env.GATSBY_LANGUAGE]}
              </p>
              <div id="previewContainer" className="w-100 mw5 h5 center">
                {this.renderCropper()}
              </div>
              <EffectSelect onChange={this.handleEffectChange} />
              <Button size={4} color="theme" inversed={true} label={data.squidexTexts.btnChangePicture[process.env.GATSBY_LANGUAGE]} onClick={this.handleClearImage} />
            </div>
          )
        } />
    )
  }
  renderCropper = () => {
    const orderStore: OrderStore = this.props.orderStore;
    return (
      <Observer>{() => <Cropper
        image={orderStore.currentItem.thumb}
        crop={this.state.cropLoc}
        aspect={orderStore.currentItem.aspectRatio}
        zoom={this.state.zoom}
        onCropChange={this.handleCropChange}
        onZoomChange={this.handleZoomChange}
        onCropComplete={this.handleCropComplete}
        showGrid={false}
        style={
          {
            containerStyle: { position: "relative", width: "100%", height: "100%" },
          }
        }
      />}</Observer>
    )
  }
  handleCropChange = (cropLoc: ICropLocation) => {
    this.setState({ cropLoc })
  }
  handleZoomChange = (zoom: number) => {
    this.setState({ zoom });
  }
  handleCropComplete = (area: ICrop, pixels: ICrop) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.crop.x = pixels.x;
    store.currentItem.crop.y = pixels.y;
    store.currentItem.crop.width = pixels.width;
    store.currentItem.crop.height = pixels.height;
  }
  handleEffectChange = (effect: string) => {
    if (this.props.onEffectChange) {
      this.props.onEffectChange(effect);
    }
  }
  handleClearImage = () => {
    if (this.props.onImageReset) {
      this.props.onImageReset();
    }
  }
}




