
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { Order, Option, OrderStore, VisitorStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_OPTION_EVENT } from "../../../utils/constants";
import { Checkbox } from 'components';
import { getLanguageValue } from 'utils';
import { SquidexTexts } from 'generated/gatsbyApiTypes';
import { optionCSS } from 'react-select/lib/components/Option';

export interface OptionGroupProps {
  orderStore?: OrderStore;
  visitorStore?: VisitorStore;
}

@inject("orderStore", "visitorStore")
@observer
export default class OptionGroups extends React.Component<OptionGroupProps> {
  render() {
    const orderStore = this.props.orderStore;
    if (!orderStore.currentItem.material || !orderStore.currentItem.material.optionGroups || orderStore.currentItem.material.optionGroups.length === 0) {
      return null;
    }
    const groups = orderStore.currentItem.material.optionGroups;
    return (
      <>
        {
          groups.map((group, i) => {
            if (group.type === 'ChooseOne') {
              return (
                <StaticQuery key={`optiongroup_${group.name}`} query={graphql`
                  {
                    squidexTexts(identity:{eq: "squidextexts"}) {
                      txtChooseOneNoOptionSelected {
                        de
                        nl
                        en
                      }
                    }
                  }`} render={
                    (data: {squidexTexts: SquidexTexts}) => {
                      const items = group.options
                        .map(opt => ({ label: opt.name, value: opt }));

                      let defaultItem: SelectListItem;
                      if (!group.choiceRequired) {
                        defaultItem = { label: getLanguageValue(data.squidexTexts.txtChooseOneNoOptionSelected), value: null };
                        items.push(defaultItem);
                      } else {
                        defaultItem = items[0];
                      }
                      const selectedIndex = orderStore.currentItem.options.findIndex((opt) => {
                        return group.options.map(o => o.id).indexOf(opt.id) !== -1
                      });
                      if (selectedIndex !== -1) {
                        const opt = orderStore.currentItem.options[selectedIndex];
                        defaultItem = { label: opt.name, value: opt }
                      };
                      return (
                        <div id="format-row">
                          <label className="f4 b db mb2">{group.name}</label>
                          <SelectList defaultValue={defaultItem} items={items} onChange={this.handleChooseOneChange(i)} />
                        </div>
                      )
                    }} />
              )
            } else {
              return (
                <div id="format-row" key={`optiongroup_${group.name}`}>
                  <label className="f4 b db mb2">{group.name}</label>
                  {group.options.map(opt => (
                    <Checkbox
                      key={`optiongroup_${group.name}_${opt.id}`}
                      checked={orderStore.currentItem.options.some(o => o.id === opt.id)}
                      onChange={this.handleChooseAnyChange(opt)}
                      label={opt.name}
                    />
                  ))}
                </div>
              )
            }
          }
          )
        }
      </>
    )
  }

  handleChooseOneChange = (groupIndex: number) => (t: SelectListItem) => {
    const orderStore: OrderStore = this.props.orderStore;
    const group = orderStore.currentItem.material.optionGroups[groupIndex];
    const currentSelectedOption = orderStore.currentItem.options.findIndex((opt) => {
      return group.options.map(o => o.id).indexOf(opt.id) !== -1
    });
    if (t.value !== null) {
      if (currentSelectedOption !== -1) {
        orderStore.currentItem.options[currentSelectedOption] = t.value;
      } else {
        orderStore.currentItem.options.push(t.value);
      }

      const visitorStore: VisitorStore = this.props.visitorStore;
      visitorStore.logEvent(SELECT_OPTION_EVENT, t.value);
    } else {
      if (currentSelectedOption !== -1) {
        orderStore.currentItem.options.splice(currentSelectedOption, 1);
      }
    }
  }

  handleChooseAnyChange = (option: Option) => (checked: boolean) => {
    const orderStore: OrderStore = this.props.orderStore;
    if (checked) {
      orderStore.currentItem.options.push(option);
    } else {
      orderStore.currentItem.options = orderStore.currentItem.options.filter(i => i.id !== option.id);
    }
  }
}


