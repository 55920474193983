
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, ShopStore, VisitorStore, Material } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_MATERIAL_EVENT } from "../../../utils/constants";

export interface MaterialSelectProps {
  orderStore?: OrderStore;
  shopStore?: ShopStore;
  visitorStore?: VisitorStore;
}

@inject("orderStore", "shopStore", "visitorStore")
@observer
export default class MaterialSelect extends React.Component<MaterialSelectProps> {
  render() {
    const store: ShopStore = this.props.shopStore;
    const materials = store.current.materials.map(m => ({ label: m.name, value: m }));
    let defaultValue: SelectListItem = undefined;
    if (this.props.orderStore.currentItem.material) {
      defaultValue = materials.find(m => m.label === this.props.orderStore.currentItem.material.name);
    }
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtMaterial {
            de
            nl
            en
          }
          txtChooseMaterial {
            de
            nl
            en
          }
        }
      }`} render={
          (data) => (
            <div id="material-row">
              <label className="f4 b db mb2">{data.squidexTexts.txtMaterial[process.env.GATSBY_LANGUAGE]}</label>
              <SelectList defaultValue={defaultValue} placeholder={data.squidexTexts.txtChooseMaterial[process.env.GATSBY_LANGUAGE]} items={materials} onChange={this.handleMaterialChange} />
            </div>
          )
        } />
    )
  }


  handleMaterialChange = (t: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    const visitorStore: VisitorStore = this.props.visitorStore;
    const material: Material = t.value;
    visitorStore.logEvent(SELECT_MATERIAL_EVENT, {
      id: material.id,
      name: name
    });
    store.currentItem.material = t.value;
    if (!store.currentItem.isCustomSize) {
      store.currentItem.filter = store.currentItem.material.formats[0].houding;
      store.currentItem.format = t.value.formats[0];
      store.currentItem.rawPrice = t.value.formats[0].price;
    } else {
      store.getCustomPrice();
    }
    if (t.value.options && t.value.options.length > 0) {
      store.currentItem.options = [t.value.options[0]];
    } else {
      store.currentItem.options = [];
    }
  }
}


