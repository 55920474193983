
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react";

import { OrderStore, VisitorStore } from "stores";
import SelectList, { SelectListItem } from "../../../components/selectList";
import { SELECT_FORMAT_FILTER_EVENT } from "../../../utils/constants";

export interface FilterSelectProps {
  onChange?: (filter: string) => void;
  orderStore?: OrderStore;
  visitorStore?: VisitorStore;
}

@inject("orderStore", "visitorStore")
@observer
export default class FilterSelect extends React.Component<FilterSelectProps> {
  componentWillMount() {
    const store: OrderStore = this.props.orderStore;
    if (store.currentItem.material) {
      const filter = store.currentItem.material.formats[0].houding;
      if (!store.currentItem.filter) {
        store.currentItem.filter = filter;
      }
    }
  }
  render() {
    const orderStore: OrderStore = this.props.orderStore;
    if (!orderStore.currentItem.material || orderStore.currentItem.isCustomSize) {
      return null;
    }
    return (
      <StaticQuery query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtOrientation {
            de
            nl
            en
          }
          txtPortrait {
            de
            nl
            en
          }
          txtLandscape {
            de
            nl
            en
          }
          txtSquare {
            de
            nl
            en
          }
        }
      }`} render={
        (data) => {
          const filters = Array.from(new Set(orderStore.currentItem.material.formats.map(f => f.houding)))
            .map(f => ({ label: this.translate(f, data), value: f }))
          const defaultValue = filters.find(f => f.value === orderStore.currentItem.filter);
    
          return (
          <div id="filter-row">
            <label className="f4 b db mb2">{data.squidexTexts.txtOrientation[process.env.GATSBY_LANGUAGE]}</label>
            <SelectList defaultValue={defaultValue} items={filters} onChange={this.handleChange} />
          </div>
        )}
      } />
    )
  }
  handleChange = (t: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.currentItem.filter = t.value;
    store.currentItem.format = store.currentItem.material.formats.filter(f => f.houding === t.value)[0];
    if (this.props.onChange) {
      this.props.onChange(t.value);
    }
  }
  translate = (filter: string, data: any) => {
    if (filter == "Staand") {
      return data.squidexTexts.txtPortrait[process.env.GATSBY_LANGUAGE];
    }
    if (filter == "Liggend") {
      return data.squidexTexts.txtLandscape[process.env.GATSBY_LANGUAGE];
    }
    if (filter == "Vierkant") {
      return data.squidexTexts.txtSquare[process.env.GATSBY_LANGUAGE];
    }
    return filter;
  }
}


